import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { initNamingProcess } from '../../+store/apkv.actions';
import { ApkvService } from '../../apkv.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  constructor(
    private readonly apkvService: ApkvService,
    private readonly store: Store,
  ) {}

  public ngOnInit(): void {
    this.apkvService.url = null;
    this.apkvService.apkvFormState = null;
    this.store.dispatch(initNamingProcess());
  }
}
