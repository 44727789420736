import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CountDownStore, UtilsService, PdfTypesEnum } from '../../../shared';
import { downloadPdf } from '../../+store/apkv.actions';
import {
  selectLoading,
  selectNamingProcessData,
} from '../../+store/apkv.selectors';
import { ApkvLoadingState, NamingProcessData } from '../../apkv.model';

@Component({
  selector: 'app-dummypdf',
  templateUrl: './apkv-bestaetigung.component.html',
  styleUrls: ['./apkv-bestaetigung.component.scss'],
})
export class ApkvBestaetigungComponent implements AfterViewInit {
  namingProcessData$ = this.store.select(selectNamingProcessData);
  loading$ = this.store.select(selectLoading);

  constructor(
    private utilsService: UtilsService,
    public countDownStore: CountDownStore,
    private readonly store: Store,
  ) {}

  handleSubmit(): void {
    this.store.dispatch(downloadPdf({ pdfType: PdfTypesEnum.bestaetigung }));
  }

  ngAfterViewInit(): void {
    this.utilsService.scrollToTop();
  }

  getButtonLabel(namingProcessData: NamingProcessData): string {
    return namingProcessData?.bestaetigungDownloaded
      ? 'Bestätigung erneut herunterladen (PDF)'
      : 'Bestätigung herunterladen (PDF)';
  }

  getButtonColor(
    namingProcessData: NamingProcessData,
  ): 'primary' | 'secondary' {
    return namingProcessData?.bestaetigungDownloaded ? 'secondary' : 'primary';
  }

  isDownloaded(namingProcessData: NamingProcessData): boolean {
    return !!namingProcessData?.bestaetigungDownloaded;
  }

  isLoading(loading: ApkvLoadingState): boolean {
    return !!loading?.downloadbestaetigung;
  }
}
