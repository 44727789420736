import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { PersonFormObject } from './apkv.model';
import { environment } from 'src/environments/environment';
import { ApkvService } from './apkv.service';
import { selectApkvState, selectSessionId } from './+store/apkv.selectors';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { destroySession, initStore } from './+store/apkv.actions';
import { CountDownStore, PdfTypesEnum, UtilsService } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class ApkvStore {
  private backendEndpoint = environment.backendEndpoint;
  private httpClientWithoutInterceptor: HttpClient;

  constructor(
    private http: HttpClient,
    httpBE: HttpBackend,
    private apkvService: ApkvService,
    private utilsService: UtilsService,
    private countDownStore: CountDownStore,
    private store: Store,
    private readonly router: Router,
  ) {
    this.httpClientWithoutInterceptor = new HttpClient(httpBE);
  }

  public navigateToLoggedOut(): void {
    this.store.dispatch(destroySession());
    this.router.navigate(['/logged-out']);
    this.store.dispatch(initStore());
  }

  public navigateToOverview(): void {
    this.router
      .navigate([`/apkv/overview`])
      .then(() => this.apkvService.apkvFormState.reset());
  }

  public checkEmailTwiceValidator(personObject, emails): any {
    return Object.keys(personObject).reduce((acc, curr) => {
      if (
        emails.filter(
          (item) => item?.toLowerCase() === personObject[curr]?.toLowerCase(),
        ).length > 1
      ) {
        acc = { ...acc, [curr]: 'DOPPELT' };
      } else {
        acc = { ...acc, [curr]: 'GUELTIG' };
      }
      return acc;
    }, {});
  }

  /**
   * APKVs abrufen
   *
   * @param sessionId
   */
  public getApkvData(sessionId: string): Observable<any> {
    return this.http.get<any>(`${this.backendEndpoint}/v1/apkvs`, {
      headers: {
        'X-Ogr-SessionId': sessionId,
      },
    });
  }

  public checkCode(code: string, pin: string): Observable<any> {
    return this.http.get(
      `${this.backendEndpoint}/v1/zugangscodes/verifiziere`,
      {
        responseType: 'text',
        headers: {
          'X-Ogr-Zugangscode': code,
          'X-Ogr-Pin': pin,
        },
      },
    );
  }

  public checkApkvEmail(emailObject: PersonFormObject): Observable<any> {
    this.countDownStore.resetCounterDuration();
    return this.sendPostRequestWithSessionId(
      `${this.backendEndpoint}/v1/apkvs/pruefe-email-duplikate`,
      { ...emailObject },
    );
  }

  public deactivateApkv(apkvEmail: string): Observable<any> {
    return this.sendPatchRequestWithSessionId(
      `${this.backendEndpoint}/v1/apkvs`,
      { email: apkvEmail },
    );
  }

  public createApkv(apkvData: any): Observable<any> {
    const liste = this.apkvService.getApkvDataArrayByObject(apkvData);
    return this.sendPostRequestWithSessionId(
      `${this.backendEndpoint}/v1/apkvs`,
      { liste },
    );
  }

  public generatePdfByType(
    type: PdfTypesEnum,
    apkvData: any,
    apkv?,
  ): Observable<any> {
    this.countDownStore.resetCounterDuration();
    const { route } = this.utilsService.getPdfObjectByType(type);
    let body;
    if (type === PdfTypesEnum.erneuereRegistrierungscode) {
      body = { apkv };
    } else {
      const liste = this.apkvService.getApkvDataArrayByObject(apkvData);
      body = { liste };
    }
    return this.sendPostRequestWithSessionId(
      `${this.backendEndpoint}/v1/apkvs/${route}`,
      body,
    );
  }

  public sendApkvData(): Observable<any> {
    const stateData = this.utilsService.getStoredValue(
      this.store.select(selectApkvState),
    );
    const liste = this.apkvService.getApkvDataArrayByObject(
      stateData?.namingprocess?.apkvData,
    );
    return this.sendPostRequestWithSessionId(
      `${this.backendEndpoint}/v1/apkvs`,
      { liste },
    );
  }

  public refreshSession(): Observable<any> {
    const sessionId = this.utilsService.getStoredValue(
      this.store.select(selectSessionId),
    );
    if (sessionId) {
      return this.httpClientWithoutInterceptor.get<any>(
        `${this.backendEndpoint}/v1/session/refresh`,
        {
          headers: {
            'X-Ogr-SessionId': sessionId,
          },
        },
      );
    }
    return EMPTY;
  }

  public destroySession(): Observable<any> {
    const sessionId = this.utilsService.getStoredValue(
      this.store.select(selectSessionId),
    );
    return this.httpClientWithoutInterceptor.get<any>(
      `${this.backendEndpoint}/v1/session/invalidiere`,
      {
        headers: {
          'X-Ogr-SessionId': sessionId,
        },
      },
    );
  }

  /**
   * Post Request ans Backend senden inkl. der Session ID
   *
   * @param url
   * @param body
   * @private
   */
  private sendPostRequestWithSessionId(
    url: string,
    body: any,
  ): Observable<any> {
    const stateData = this.utilsService.getStoredValue(
      this.store.select(selectApkvState),
    );
    return this.http.post(url, body, {
      headers: {
        'X-Ogr-SessionId': stateData.sessionId,
      },
    });
  }

  /**
   * Patch Request ans Backend senden inkl. der Session ID
   *
   * @param url
   * @param body
   * @private
   */
  private sendPatchRequestWithSessionId(
    url: string,
    body: any,
  ): Observable<any> {
    const stateData = this.utilsService.getStoredValue(
      this.store.select(selectApkvState),
    );
    return this.http.patch(url, body, {
      headers: {
        'X-Ogr-SessionId': stateData.sessionId,
      },
    });
  }
}
