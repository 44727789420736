import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeedbackService } from 'src/app/shared/components/feedback/feedback.service';
import { Router } from '@angular/router';
import { UtilsService, CountDownStore, APKV_STATES } from '../../../shared';
import { Store } from '@ngrx/store';
import {
  selectApkvList,
  selectSessionId,
  selectEkhData,
} from '../../+store/apkv.selectors';
import { TableConfigItem } from '../../../shared/components/table-generator/table-generator.model';

@Component({
  selector: 'app-uebersicht',
  templateUrl: './apkv-uebersicht.component.html',
  styleUrls: ['./apkv-uebersicht.component.scss'],
})
export class ApkvUebersichtComponent implements OnInit, OnDestroy {
  sessionId: string;

  public readonly ekh$ = this.store.select(selectEkhData);

  public readonly apkvList$ = this.store.select(selectApkvList);

  public readonly tableConfig: TableConfigItem[] = [
    {
      key: 'fullname',
      columnSize: 1,
      type: 'fullName',
      title: 'Name',
      cssClass: 'large',
    },
    {
      key: 'email',
      columnSize: 1,
      type: 'string',
      title: 'E-Mail-Adresse',
      cssClass: 'large',
    },
    {
      key: 'telefonnummer',
      columnSize: 1,
      type: 'string',
      title: 'Telefon',
      cssClass: 'small',
    },
    {
      key: 'statusDatum',
      columnSize: 1,
      type: 'string',
      title: 'aktiv seit',
      cssClass: 'small',
    },
    {
      key: 'status',
      columnSize: 1,
      type: 'state',
      title: 'Status',
      cssClass: 'small',
    },
    {
      key: 'action',
      columnSize: 1,
      type: 'action',
      title: 'Aktion',
      cssClass: 'large',
    },
  ];

  public readonly APKV_STATE_LIST = {
    AKTIV: { label: 'aktiv', color: 'green' },
    INAKTIV: { label: 'inaktiv', color: 'grey' },
    BENANNT: { label: 'benannt', color: 'yellow' },
    FREIGEGEBEN: { label: 'freigegeben', color: 'yellow' },
  };
  public readonly CREATE_STATES = APKV_STATES;

  public readonly APKV_LIMIT = 3;

  constructor(
    private feedbackService: FeedbackService,
    private router: Router,
    public utilsService: UtilsService,
    private store: Store,
    private readonly countDownStore: CountDownStore,
  ) {}

  public ngOnInit(): void {
    this.sessionId = this.utilsService.getStoredValue(
      this.store.select(selectSessionId),
    );
    if (!this.sessionId) {
      this.router.navigate(['/apkv/check-code']);
    }
  }

  public handleCreate(): void {
    this.countDownStore.resetCounterDuration();
    this.router.navigate(['/apkv/naming']);
  }

  public isCreateActive(): boolean {
    const apkvList = this.utilsService.getStoredValue(
      this.store.select(selectApkvList),
    );
    const apkvActiveListe = apkvList?.filter((item) =>
      Object.keys(this.CREATE_STATES).includes(item?.status),
    );
    return apkvActiveListe.length < this.APKV_LIMIT;
  }

  public ngOnDestroy(): void {
    this.feedbackService.resetFeedbackObject();
  }

  public getValue(value: string): string {
    return this.utilsService.getValue(value);
  }
}
