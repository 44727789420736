import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { InfoPage, SiteState } from 'src/app/shared/_enums/infopages';
import { Store } from '@ngrx/store';
import { selectSessionId } from '../overview/+store/apkv.selectors';
import { ApkvService } from '../overview/apkv.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  lastPathSegment: string;

  subscription = new Subscription();

  pageState: Set<SiteState> = new Set<SiteState>();

  infoPageMenus: InfoPage[] = [
    {
      label: 'Hilfe',
      link: '/hilfe',
      isAbsolute: false,
      requiredStates: [SiteState.LOGGED_IN],
    },
    {
      label: 'Impressum',
      link: '/impressum',
      isAbsolute: false,
      requiredStates: [],
    },
    {
      label: 'Datenschutz',
      link: '/datenschutz',
      isAbsolute: false,
      requiredStates: [],
    },
    {
      label: 'Rechtliche Hinweise',
      link: '/rechtliche-hinweise',
      isAbsolute: false,
      requiredStates: [],
    },
    {
      label: 'Barrierefreiheit',
      link: '/barrierefreiheit',
      isAbsolute: false,
      requiredStates: [],
    },
    {
      label: 'Barriere melden',
      link: (currentUrl: string) => {
        const subject = encodeURIComponent('Barriere im EKH-Portal');
        const body = encodeURIComponent(
          `Es wurde eine Barriere gefunden auf ${currentUrl}.\r\nBeschreibung der Barriere:\r\n`,
        );
        return `mailto:kontakt@organspende-register.de?subject=${subject}&body=${body}`;
      },
      isAbsolute: true,
      requiredStates: [],
    },
  ];

  constructor(
    private router: Router,
    private apkvService: ApkvService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(selectSessionId).subscribe((sessionId) => {
        this.pageState.clear();
        if (sessionId == null) {
          this.pageState.add(SiteState.LOGGED_OUT);
        } else {
          this.pageState.add(SiteState.LOGGED_IN);
        }
      }),
    );
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((event: NavigationEnd) => event.url),
        )
        .subscribe((url: string) => {
          this.lastPathSegment = url?.split('/').pop();
        }),
    );
  }

  hasPageStates(requiredStates: SiteState[]): boolean {
    return requiredStates.every((requiredState) =>
      this.pageState.has(requiredState),
    );
  }

  onNavigate(infoPage: InfoPage): void {
    const processUrl = this.router.url;
    if (!this.apkvService.backLinksAvailableOn.includes(processUrl)) {
      this.apkvService.url = processUrl;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public renderUrl(
    infoPage: InfoPage,
  ): ((currentUrl: string) => string) | string {
    if (typeof infoPage.link === 'string') {
      return infoPage.link;
    } else {
      return infoPage.link(window.location.href);
    }
  }

  public isCurrentPage(infoPage: InfoPage): boolean {
    return (
      this.lastPathSegment &&
      (infoPage.link as string).includes(this.lastPathSegment)
    );
  }

  public onLoginPage(): boolean {
    return this.lastPathSegment?.includes('check-code');
  }
}
