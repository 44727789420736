import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CountDownStore } from '../../../shared';
import { setPreviousNavigationIndex } from '../../+store/apkv.actions';

@Component({
  selector: 'app-navigation-previous-button',
  templateUrl: './navigation-previous-button.component.html',
})
export class NavigationPreviousButtonComponent {
  constructor(
    private readonly store: Store,
    private readonly countDownStore: CountDownStore,
  ) {}

  handlePrevious(): void {
    this.countDownStore.resetCounterDuration();
    this.store.dispatch(setPreviousNavigationIndex());
  }
}
