import { Component, OnInit } from '@angular/core';
import { ApkvStore } from '../overview/apkv.store';
import { UtilsService } from '../shared/services/utils.service';

@Component({
  selector: 'app-loggedout',
  templateUrl: './loggedout.component.html',
  styleUrls: ['./loggedout.component.scss'],
})
export class LoggedOutComponent implements OnInit {
  constructor(
    private apkvStore: ApkvStore,
    private readonly utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.utilsService.scrollToTop();
  }
}
