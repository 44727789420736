import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UtilsService, PdfTypesEnum } from '../../../shared';
import { downloadPdf } from '../../+store/apkv.actions';
import {
  selectLoading,
  selectNamingProcessData,
} from '../../+store/apkv.selectors';

@Component({
  selector: 'app-apkv-zugangscodes-schreiben',
  templateUrl: './apkv-zugangscodes-schreiben.component.html',
  styleUrls: ['./apkv-zugangscodes-schreiben.component.scss'],
})
export class ApkvZugangscodesSchreibenComponent implements AfterViewInit {
  public namingProcessData$ = this.store.select(selectNamingProcessData);
  public loading$ = this.store.select(selectLoading);

  constructor(
    private readonly utilsService: UtilsService,
    private readonly store: Store,
  ) {}

  public handleSubmit(): void {
    this.store.dispatch(downloadPdf({ pdfType: PdfTypesEnum.zugang }));
  }

  public ngAfterViewInit(): void {
    this.utilsService.scrollToTop();
  }

  public getButtonLabel(codesDownloaded: boolean): string {
    return codesDownloaded
      ? 'Zugangscodes erneut herunterladen (PDF)'
      : 'Zugangscodes herunterladen (PDF)';
  }
}
