import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeedbackService } from 'src/app/shared/components/feedback/feedback.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { selectSessionId } from '../../+store/apkv.selectors';
import { retrieveApkvList } from '../../+store/apkv.actions';

@Component({
  selector: 'app-apkv-thank-you',
  templateUrl: './apkv-thank-you.component.html',
  styleUrls: ['./apkv-thank-you.component.scss'],
})
export class ApkvThankYouComponent implements OnDestroy, AfterViewInit {
  code: string;

  constructor(
    private router: Router,
    private feedbackService: FeedbackService,
    private utilsService: UtilsService,
    private readonly store: Store,
  ) {}

  public ngOnDestroy(): void {
    this.feedbackService.resetFeedbackObject();
  }

  public ngAfterViewInit(): void {
    this.utilsService.scrollToTop();
  }

  public handleGoToApkvList(): void {
    // reload the apkvlist
    const sessionId = this.utilsService.getStoredValue(
      this.store.select(selectSessionId),
    );
    this.store.dispatch(retrieveApkvList({ sessionId: sessionId }));
    this.backToOverview();
  }

  private backToOverview(): void {
    this.router.navigate([`/apkv/overview`]);
  }
}
