import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BenennungsprozessPersonenObject,
  PersonenObject,
} from './apkv-personen.model';
import { FeedbackService } from 'src/app/shared/components/feedback/feedback.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CountDownStore } from 'src/app/shared/components/countdown/countdown.store';
import { ApkvStore } from '../../apkv.store';
import { ApkvPersonModel } from '../../apkv.model';
import { Store } from '@ngrx/store';
import {
  selectApkvList,
  selectNamingProcessData,
  selectPersonenformObject,
  selectLoading,
} from '../../+store/apkv.selectors';
import {
  checkApkvEmailsInUserAccount,
  setNextNavigationIndex,
} from '../../+store/apkv.actions';
import { Router } from '@angular/router';
import {
  APKV_STATES,
  PopupService,
  ArztNameValidator,
  REGEX_NAME,
  REGEX_TITEL,
  REGEX_EMAIL,
  REGEX_ARZT_NAME,
  REGEX_PHONE,
} from '../../../shared';
import { ApkvService } from '../../apkv.service';

@Component({
  selector: 'app-apkv-personen',
  templateUrl: './apkv-personen.component.html',
  styleUrls: ['./apkv-personen.component.scss'],
})
export class ApkvPersonenComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly REGEX_TITEL = REGEX_TITEL;
  public readonly REGEX_NAME = REGEX_NAME;
  public readonly REGEX_EMAIL = REGEX_EMAIL;
  public readonly REGEX_PHONE = REGEX_PHONE;
  public readonly REGEX_ARZT_NAME = REGEX_ARZT_NAME;
  public personenForm: FormGroup;
  public showPerson2Container: boolean;
  public showPerson3Container: boolean;
  private isEmailInvalidClass: boolean;
  private overviewData: [ApkvPersonModel] = this.utilsService.getStoredValue(
    this.store.select(selectApkvList),
  );
  public loading$ = this.store.select(selectLoading);
  public shouldRestoreFormState = true;
  readonly includedStates: string[] = Object.values(APKV_STATES);

  constructor(
    private apkvStore: ApkvStore,
    private utilsService: UtilsService,
    private countDownStore: CountDownStore,
    private feedbackService: FeedbackService,
    private store: Store,
    private apkvService: ApkvService,
    private router: Router,
    private readonly popupService: PopupService,
  ) {}

  public ngAfterViewInit(): void {
    this.utilsService.scrollToTop();
  }

  public ngOnInit(): void {
    this.showPerson2Container = false;
    this.showPerson3Container = false;
    this.isEmailInvalidClass = false;
    this.personenForm = new FormGroup({
      person1: new FormGroup({
        titel: new FormControl(''),
        vorname: new FormControl('', [
          Validators.required,
          ArztNameValidator(),
        ]),
        nachname: new FormControl('', [
          Validators.required,
          ArztNameValidator(),
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(this.REGEX_EMAIL),
        ]),
        telefonnummer: new FormControl(
          '',
          Validators.pattern(this.REGEX_PHONE),
        ),
      }),
      person2: new FormGroup({
        titel: new FormControl(''),
        vorname: new FormControl('', [
          Validators.required,
          ArztNameValidator(),
        ]),
        nachname: new FormControl('', [
          Validators.required,
          ArztNameValidator(),
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(this.REGEX_EMAIL),
        ]),
        telefonnummer: new FormControl(
          '',
          Validators.pattern(this.REGEX_PHONE),
        ),
      }),
      person3: new FormGroup({
        titel: new FormControl(''),
        vorname: new FormControl('', [
          Validators.required,
          ArztNameValidator(),
        ]),
        nachname: new FormControl('', [
          Validators.required,
          ArztNameValidator(),
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(this.REGEX_EMAIL),
        ]),
        telefonnummer: new FormControl(
          '',
          Validators.pattern(this.REGEX_PHONE),
        ),
      }),
    });

    // Call machen, auf antwort warten und die Form selbst setzen......
    const personenData = this.utilsService.getStoredValue(
      this.store.select(selectNamingProcessData),
    );
    if (personenData?.apkvData) {
      for (const key of Object.keys(personenData?.apkvData)) {
        this.personenForm.controls[key]?.setValue(personenData.apkvData[key]);
        if (key === 'person2') {
          this.showPerson2Container = true;
        }
        if (key === 'person3') {
          this.showPerson3Container = true;
        }
      }
    }
    this.restoreFormStateData();
  }

  public isPersonenFormGroupValid(): boolean {
    if (this.personenForm.controls.person1.status !== 'VALID') {
      return false;
    }

    if (
      this.showPerson2Container &&
      this.personenForm.controls.person2.status !== 'VALID'
    ) {
      return false;
    }

    return !(
      this.showPerson3Container &&
      this.personenForm.controls.person3.status !== 'VALID'
    );
  }

  public getEmailErrorText(value: number): string {
    const person = `person${value}`;
    const control = this.personenForm.controls[person];

    if (control.get('email').getError('DOPPELT')) {
      return 'Diese E-Mail-Adresse ist im Formular mehrfach vorhanden.';
    }

    if (control.get('email').getError('EXISTIERT')) {
      return 'Diese E-Mail-Adresse ist bereits hinterlegt.';
    }

    return 'Bitte eine gültige berufliche E-Mail-Adresse eingeben';
  }

  public getFieldErrorText(field: string, value: number): string {
    const person = `person${value}`;
    const control = this.personenForm.controls[person];

    if (
      control.get(field).errors.pattern ||
      control.get(field).errors.arztNameInvalid ||
      control.get(field).errors.required
    ) {
      switch (field) {
        case 'titel':
          return 'Bitte einen gültigen Titel eingeben';
        case 'vorname':
          return 'Bitte einen gültigen Vornamen eingeben';
        case 'nachname':
          return 'Bitte einen gültigen Nachnamen eingeben';
        case 'telefonnummer':
          return 'Bitte eine gültige Telefonnummer eingeben';
        default:
          return 'Eingabe ungültiger Zeichen';
      }
    }
    return JSON.stringify(control.get(field).errors);
  }

  public checkEmailTwiceValidator(): void {
    const controlsEmailObject = this.getPersonObjectByPersonenForm(
      this.personenForm,
    );
    const emailValidityResponse = this.apkvStore.checkEmailTwiceValidator(
      controlsEmailObject.object,
      controlsEmailObject.emails,
    );
    this.setValidationOfPersonenForm(emailValidityResponse);
  }

  private getPersonObjectByPersonenForm(personenForm): PersonenObject {
    return Object.keys(personenForm.controls).reduce(
      (acc, curr) => {
        if (
          this.personenForm.controls[curr].get('email').value &&
          personenForm.controls[curr].get('email').value !== ''
        ) {
          acc = {
            object: {
              ...acc.object,
              [curr]: personenForm.controls[curr].get('email').value,
            },
            emails: [
              ...acc.emails,
              personenForm.controls[curr].get('email').value,
            ],
          };
        }
        return acc;
      },
      { object: {}, emails: [] },
    );
  }

  private setValidationOfPersonenForm(emailValidityResponse): void {
    if (!emailValidityResponse) {
      return;
    }

    for (const formGroupControl of Object.keys(emailValidityResponse)) {
      const patternError = this.personenForm.controls[formGroupControl]
        .get('email')
        .getError('pattern')
        ? { pattern: true }
        : null;
      this.personenForm.controls[formGroupControl]
        .get('email')
        .setErrors(patternError);

      if (emailValidityResponse[formGroupControl] === 'DOPPELT') {
        this.personenForm.controls[formGroupControl].get('email').setErrors({
          ...patternError,
          [emailValidityResponse[formGroupControl]]: true,
        });
      } else if (emailValidityResponse[formGroupControl] === 'EXISTIERT') {
        const message =
          'Für diese Person existiert bereits ein Nutzerkonto in einem Entnahmekrankenhaus. Bitte überprüfen Sie Ihre Angaben oder benennen Sie eine andere Person. Weitere Informationen finden Sie auf der Hilfe-Seite.';
        this.feedbackService.showError(message, null);
        this.personenForm.controls[formGroupControl].get('email').setErrors({
          ...patternError,
          [emailValidityResponse[formGroupControl]]: true,
        });
        document.getElementById(`${formGroupControl}-email`).focus();
      } else {
        const error = this.isEmailFieldInvalid(null, formGroupControl)
          ? { pattern: true }
          : null;
        this.personenForm.controls[formGroupControl]
          .get('email')
          .setErrors(error);
      }
    }
  }

  public isEmailFieldInvalid(value: number, personForm?: string): boolean {
    const person = personForm ? personForm : `person${value}`;
    return (
      !!this.personenForm.controls[person].get('email').errors &&
      this.isEmailTouched(person)
    );
  }

  public isEmailTouched(person: string): boolean {
    return (
      this.personenForm.controls[person].get('email').touched ||
      this.personenForm.controls[person].get('email').dirty
    );
  }

  public isFieldInvalid(field: string, value: number): boolean {
    const person = `person${value}`;
    const control = this.personenForm.controls[person];
    return (
      control.get(field).invalid &&
      (control.get(field).dirty || control.get(field).touched)
    );
  }

  public handleSubmit(): void {
    this.shouldRestoreFormState = false;
    this.checkEmailTwiceValidator();
    if (this.isPersonenFormGroupValid()) {
      const personObject = this.getValidApkvObjectByControls(
        this.personenForm.controls,
      );
      if (!this.apkvDataHaveChanged(personObject)) {
        this.store.dispatch(setNextNavigationIndex());
      } else {
        const personObjectCopy = JSON.parse(JSON.stringify(personObject));
        this.store.dispatch(
          checkApkvEmailsInUserAccount({
            personObject: this.getPersonObjectByPersonenForm(this.personenForm)
              .object,
            apkvObject: personObjectCopy,
          }),
        );
        this.store
          .select(selectPersonenformObject)
          .subscribe((personenformObject) => {
            this.setValidationOfPersonenForm(personenformObject);
          });
      }
    }
  }

  public apkvDataHaveChanged(apkvData): boolean {
    const currentApkvData = this.utilsService.getStoredValue(
      this.store.select(selectNamingProcessData),
    ).apkvData;
    // no updateApkvData
    if (!currentApkvData) {
      return true;
    }
    const isApkvDataChangedObject = Object.keys(apkvData).reduce(
      (acc, curr) => {
        if (apkvData[curr] && !currentApkvData[curr]) {
          acc = { ...acc, [curr]: true };
          return acc;
        }
        const currentStoredObject = currentApkvData[curr];
        const currentUpdateObject = apkvData[curr];
        const foundData = Object.keys(apkvData[curr]).find(
          (item) => currentUpdateObject[item] !== currentStoredObject[item],
        );
        const value = !!foundData;
        return { ...acc, [curr]: value };
      },
      { person1: false, person2: false, person3: false },
    );
    // apkvData not same as currentapkvData
    if (
      currentApkvData &&
      Object.keys(apkvData).length !== Object.keys(currentApkvData).length
    ) {
      return true;
    }
    return (
      isApkvDataChangedObject.person1 ||
      isApkvDataChangedObject.person2 ||
      isApkvDataChangedObject.person3
    );
  }

  public handleWeiterePersonenButtonClicked(): void {
    this.countDownStore.resetCounterDuration();
    if (this.showPerson2Container === false) {
      this.showPerson2Container = true;
    } else if (this.showPerson3Container === false) {
      this.showPerson3Container = true;
    }
  }

  public isPersonEntfernenButtonAvailable(index: number): boolean {
    if (index === 0) {
      return false;
    }
    if (index === 1 && this.showPerson3Container) {
      return false;
    }
    return !(index === 2 && !this.showPerson3Container);
  }

  public handlePersonEntfernenButtonClicked(): void {
    this.countDownStore.resetCounterDuration();
    if (this.showPerson2Container && this.showPerson3Container) {
      this.showPerson3Container = false;
    } else if (this.showPerson2Container) {
      this.showPerson2Container = false;
    }
    this.personenForm.controls.person3.reset();
    if (!this.showPerson2Container) {
      this.personenForm.controls.person2.reset();
    }
  }

  public isMaxPersonNumberReached(): boolean {
    // getting active stored apkv from overview
    const activeOverviewData = this.overviewData?.filter((item) =>
      this.includedStates.includes(item?.status),
    );
    return (
      this.showPerson3Container ||
      (this.showPerson2Container && activeOverviewData.length >= 1) ||
      activeOverviewData.length >= 2
    );
  }

  public hasFirstAPKV(): boolean {
    const activeData = this.overviewData?.filter((item) =>
      this.includedStates.includes(item?.status),
    );
    return activeData.length >= 1;
  }

  public getFormObjectKeys(): string[] {
    return Object.keys(this.personenForm.controls);
  }

  public showPersonContainer(formGroupName: string): boolean {
    return (
      formGroupName === 'person1' ||
      (formGroupName === 'person2' && this.showPerson2Container) ||
      (formGroupName === 'person3' && this.showPerson3Container)
    );
  }

  public getValidApkvObjectByControls(
    controls,
  ): BenennungsprozessPersonenObject {
    const person1Copy = JSON.parse(JSON.stringify(controls.person1.value));
    let personObject: BenennungsprozessPersonenObject = {
      person1: { ...person1Copy, titel: person1Copy.titel ?? '' },
    };
    if (this.showPerson2Container) {
      const person2Copy = JSON.parse(JSON.stringify(controls.person2.value));
      personObject = {
        ...personObject,
        person2: { ...person2Copy, titel: person2Copy.titel ?? '' },
      };
    }
    if (this.showPerson3Container) {
      const person3Copy = JSON.parse(JSON.stringify(controls.person3.value));
      personObject = {
        ...personObject,
        person3: { ...person3Copy, titel: person3Copy.titel ?? '' },
      };
    }
    return personObject;
  }

  public getPersonLabel(person: number): string {
    return `Person ${person} entfernen`;
  }

  public ngOnDestroy(): void {
    if (this.shouldRestoreFormState) {
      this.saveFormStateData();
    }
  }

  /**
   * Zwischengespeicherte Form-Daten abrufen
   */
  private restoreFormStateData(): void {
    if (this.apkvService.apkvFormState) {
      this.personenForm.patchValue(this.apkvService.apkvFormState.value);
      this.apkvService.apkvFormState = null;
    }
  }

  /**
   * Formulardaten zwischenspeichern
   */
  private saveFormStateData(): void {
    this.apkvService.apkvFormState = this.personenForm;
  }

  public handleCancel(): void {
    this.popupService.openCancelPopup('navigateToOverview');
  }
}
