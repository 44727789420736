import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { PopupService } from './popup.store';
import { ApkvStore } from '../../../overview/apkv.store';
import { Store } from '@ngrx/store';
import * as actions from '../../../overview/+store/apkv.actions';
import { CountDownStore } from '../countdown/countdown.store';
import { PopupModel } from './popup.model';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  public popupConfig$: Observable<PopupModel>;

  constructor(
    private popupService: PopupService,
    private apkvStore: ApkvStore,
    private countdownStore: CountDownStore,
    private readonly store: Store,
  ) {}

  public ngOnInit(): void {
    this.popupConfig$ = this.popupService.getPopupConfig();
  }

  public popupAction(popupConfig: PopupModel): void {
    if (popupConfig?.actionName) {
      const { actionName, actionProps } = popupConfig;
      this.store.dispatch(actions[actionName](actionProps));
    } else {
      const { serviceFunction, serviceName, functionParameter } = popupConfig;
      this[serviceName][serviceFunction](functionParameter);
    }
    this.close();
  }

  public close(): void {
    this.popupService.togglePopup();
  }

  public closeAction(popupConfig: PopupModel): void {
    if (popupConfig?.serviceCloseFunction) {
      const { serviceCloseFunction, serviceCloseName, functionCloseParameter } =
        popupConfig;
      this[serviceCloseName][serviceCloseFunction](functionCloseParameter);
    }
    this.close();
  }
}
