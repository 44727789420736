import { AfterViewInit, Component } from '@angular/core';
import { ApkvService } from '../../apkv.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CountDownStore } from 'src/app/shared/components/countdown/countdown.store';
import { Store } from '@ngrx/store';
import { createApkv } from '../../+store/apkv.actions';
import {
  selectLoading,
  selectNamingProcessData,
} from '../../+store/apkv.selectors';

@Component({
  selector: 'app-apkv-absende-daten',
  templateUrl: './apkv-absende-daten.component.html',
})
export class ApkvAbsendeDatenComponent implements AfterViewInit {
  public namingProcessData$ = this.store.select(selectNamingProcessData);

  public loading$ = this.store.select(selectLoading);

  constructor(
    public apkvService: ApkvService,
    private utilsService: UtilsService,
    private countDownStore: CountDownStore,
    private readonly store: Store,
  ) {}

  public handleSubmit(): void {
    this.countDownStore.resetCounterDuration();
    this.createApkv();
  }

  public createApkv(): void {
    this.store.dispatch(createApkv());
  }

  public ngAfterViewInit(): void {
    this.utilsService.scrollToTop();
  }
}
