import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { IdentifikationspersistenzActions } from './identifikationspersistenz.actions';
import { selectSessionId } from './apkv.selectors';
import { retrieveApkvList } from './apkv.actions';

@Injectable()
export class IdentifikationspersistenzEffects implements OnInitEffects {
  identifikationspersistenzPruefung$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        IdentifikationspersistenzActions.identifikationspersistenzPruefung,
      ),
      map(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        if (sessionId) {
          return IdentifikationspersistenzActions.identifikationspersistenzPruefungErfolgreich(
            { sessionId: sessionId },
          );
        }
        return IdentifikationspersistenzActions.identifikationspersistenzPruefungFehlgeschlagen();
      }),
    ),
  );

  identifikationspersistenzPruefungErfolgreich$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        IdentifikationspersistenzActions.identifikationspersistenzPruefungErfolgreich,
      ),
      map((action) => retrieveApkvList({ sessionId: action.sessionId })),
    ),
  );

  serialize$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(
          IdentifikationspersistenzActions.identifikationspersistenzPruefungErfolgreich,
          IdentifikationspersistenzActions.identifikationspersistenzPruefungFehlgeschlagen,
        ),
        switchMap(() => this.store.select(selectSessionId)),
        distinctUntilChanged(),
        tap((sessionId) => {
          if (sessionId) {
            sessionStorage.setItem('sessionId', sessionId);
          } else {
            sessionStorage.removeItem('sessionId');
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly action$: Actions,
    private readonly store: Store,
  ) {}

  ngrxOnInitEffects(): Action {
    return IdentifikationspersistenzActions.identifikationspersistenzPruefung();
  }
}
